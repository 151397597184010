import {
  css3,
  html5,
  javascript,
  nodejs,
  python,
  expressjs,
  django,
  mongodb,
  postgresql,
  react,
  typescript,
  sass,
  semantic,
  uikit,
  bootstrap,
  npm,
  yarn,
  insomnia,
  azuredevops,
  cypress,
  git,
  optimizely,
  postman,
  redux,
  vercel,
} from "../../tech-logos";

export const TechStackData = [
  {
    key: 0,
    src: html5,
    alt: "html",
    span: "HTML 5",
  },
  {
    key: 1,
    src: css3,
    alt: "css",
    span: "CSS",
  },
  {
    key: 2,
    src: javascript,
    alt: "javascript",
    span: "Javascript",
  },
  {
    key: 3,
    src: python,
    alt: "python",
    span: "Python",
  },
  {
    key: 4,
    src: react,
    alt: "react",
    span: "React.js",
  },
  {
    key: 5,
    src: typescript,
    alt: "typescript",
    span: "Typescript",
  },
  {
    key: 6,
    src: nodejs,
    alt: "NodeJs",
    span: "NodeJs",
  },
  {
    key: 7,
    src: expressjs,
    alt: "expressjs",
    span: "ExpressJs",
  },
  {
    key: 8,
    src: django,
    alt: "Django",
    span: "Django",
  },
  {
    key: 9,
    src: mongodb,
    alt: "mongodb",
    span: "MongoDB",
  },
  {
    key: 10,
    src: postgresql,
    alt: "postgresql",
    span: "PostgreSQL",
  },
  {
    key: 11,
    src: sass,
    alt: "SASS",
    span: "SASS",
  },
  {
    key: 12,
    src: semantic,
    alt: "semantic",
    span: "Semantic UI",
  },
  {
    key: 13,
    src: uikit,
    alt: "uikit",
    span: "UIkit",
  },
  {
    key: 14,
    src: npm,
    alt: "npm",
    span: "npm",
  },
  {
    key: 15,
    src: yarn,
    alt: "yarn",
    span: "yarn",
  },
  {
    key: 16,
    src: insomnia,
    alt: "insomnia",
    span: "Insomnia",
  },
  {
    key: 17,
    src: bootstrap,
    alt: "bootstrap",
    span: "Bootstrap",
  },
  {
    key: 18,
    src: azuredevops,
    alt: "azuredevops",
    span: "Azuredevops",
  },
  {
    key: 19,
    src: cypress,
    alt: "cypress",
    span: "Cypress Io",
  },
  {
    key: 20,
    src: git,
    alt: "git",
    span: "Git",
  },
  {
    key: 21,
    src: optimizely,
    alt: "optimizely",
    span: "Optimizely",
  },
  {
    key: 22,
    src: postman,
    alt: "postman",
    span: "Postman",
  },
  {
    key: 23,
    src: redux,
    alt: "redux",
    span: "Redux",
  },
  {
    key: 24,
    src: vercel,
    alt: "vercel",
    span: "Vercel",
  },
];
